<template>
	<div></div>
</template>

<script>
export default {
	name: "Error404",
	mixins: [],
	components: {},
	data() {
		return {};
	},
	computed: {},
	methods: {},
	watch: {},
	created() {},
};
</script>

<style scoped

</style>